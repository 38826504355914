import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { StartUpService } from '../../services/startup/startup.service';

export const authGuard = () => {
  return async () => {
    const router: Router = inject(Router);
    const startUpService: StartUpService = inject(StartUpService);
    const localStorageService: LocalStorageService = inject(LocalStorageService);
    if (localStorageService.get('authToken')) {
      return true;
    } else {
      startUpService.resetGlobalStoreValues();
      return router.parseUrl('/auth/qr-code');
    }
  };
};
