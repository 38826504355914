import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { StartUpService } from './services/startup/startup.service';
import { WebSocketService } from './services/websocket/websocket.service';
import { LoginService } from './services/login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    if (document.visibilityState === 'hidden') {
      this.handleAppClose();
    } else {
      this.handleAppInit();
    }
  }

  /* istanbul ignore next */
  @HostListener('window:online')
  setNetworkOnline(): void {
    this.handleAppInit();
  }

  @HostListener('window:offline')
  setNetworkOffline(): void {
    this.handleAppClose();
    // navigate to offline error page
    this.router.navigate(['/errors/offline']);
  }

  constructor(
    private readonly webSocketService: WebSocketService,
    private readonly startUpService: StartUpService,
    private readonly loginService: LoginService,
    private readonly router: Router
  ) {}

  private async handleAppInit(): Promise<void> {
    if (this.loginService.getAuthToken() !== null) {
      // setting of device id will trigger new websocket connection
      await this.startUpService.init();
    }
  }

  private handleAppClose(): void {
    this.webSocketService.close();
    this.startUpService.resetInitialization();
  }
}
