import { Orchestration } from 'aws-rum-web/dist/es/orchestration/Orchestration';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AwsRumService {
  private orchestration: Orchestration;

  /* istanbul ignore next */
  constructor(private readonly router: Router) {
    this.orchestration = this.createNewOrchestration();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.orchestration.recordPageView(this.router.url);
      }
    });
  }

  /* istanbul ignore next */
  createNewOrchestration(): Orchestration {
    const awsRumConfig = { ...environment.awsRumConfig };
    if (navigator.webdriver) {
      awsRumConfig.allowCookies = false;
      awsRumConfig.enableXRay = false;
      awsRumConfig.enableRumClient = false;
    }

    return new Orchestration(
      environment.awsRumApp.applicationId,
      environment.awsRumApp.applicationVersion,
      environment.awsRumApp.region,
      awsRumConfig
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  recordError(err: any): void {
    const errorEvent = new ErrorEvent('error', { error: err, message: err?.message || err });
    this.orchestration.recordError(errorEvent);
  }
}
