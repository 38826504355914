import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartUpComponent } from './modules/shared/components/start-up/start-up.component';
import { authGuard } from './guards/auth-guard/auth.guard';
import { deviceGuardCanMatch } from './guards/device-guard/device.guard';

/* istanbul ignore next */
export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [authGuard()],
    canMatch: [deviceGuardCanMatch(), authGuard()],
  },
  {
    path: 'setup',
    loadChildren: () => import('./modules/device-setup/device-setup.module').then((m) => m.DeviceSetupModule),
    canActivate: [authGuard()],
    canMatch: [deviceGuardCanMatch(), authGuard()],
  },
  {
    path: 'provisioning',
    loadChildren: () => import('./modules/provisioning/provisioning.module').then((m) => m.ProvisioningModule),
    canActivate: [authGuard()],
    canMatch: [deviceGuardCanMatch(), authGuard()],
  },
  {
    path: 'calibration',
    loadChildren: () => import('./modules/calibration/calibration.module').then((m) => m.CalibrationModule),
    canActivate: [authGuard()],
    canMatch: [deviceGuardCanMatch(), authGuard()],
  },
  {
    path: 'pair-button',
    loadChildren: () => import('./modules/pair-button/pair-button.module').then((m) => m.PairButtonModule),
    canActivate: [authGuard()],
    canMatch: [deviceGuardCanMatch(), authGuard()],
  },
  {
    path: 'loading',
    component: StartUpComponent,
  },
  {
    path: '**',
    redirectTo: '/home/landing-page',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
