import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { StartUpService } from '../../services/startup/startup.service';

export const deviceGuardCanMatch = () => {
  return () => {
    const router = inject(Router);
    const localStorageService = inject(LocalStorageService);
    const startUpService: StartUpService = inject(StartUpService);

    if (localStorageService.get('deviceId')) {
      return true;
    }

    startUpService.resetGlobalStoreValues();
    localStorageService.remove('authToken');
    return router.parseUrl('/auth/qr-code');
  };
};
